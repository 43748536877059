body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.center{
  padding: 1%;
  align-items: center;
  text-align: center;
}

.justify {
  margin: 0 2% 0 5%;
  text-align: justify;
}

.book {
  margin: 2% 0 0 2%;
  width: 12em;
}

.bookWithBorder {
  width: 12em;
  margin: 2% 0 0 2%;
  padding-top: 1%;
  border-color: grey;
  border-width: 1px;
  border-style: solid;
}

.grid-item-header h2 {
  font-size: 2em;
}

.bookImgContainerMobile{
  display: none;
}

.bookImgContainer {
  width: 100%;
  justify-items: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-template-rows: auto auto auto auto;
  padding: 1%;
  background-color: rgba(250,240,230,0.6);
  height: 80vh;
}

.grid-item-header {
  background-color: white;
  font-family: Georgia, 'Times New Roman', Times, serif;
  grid-column: 1 / span 4;
  padding: 5%;
  text-align: center;
}

.grid-item {

  display: block;
  padding: 5%;
  text-align: center;
  background-color: white;
  grid-column: 1 / span 4;

}

.background-image{
  z-index: -1;
  filter: blur(1px);
  -webkit-filter: blur(1px);
  

}
.nav {
    /* background-image: url('./components/woodgrain.png');
    background-size: 40% 100%; */
    
    /* Add the blur effect
  filter: blur(8px);
  -webkit-filter: blur(8px); */

    /* background: #DEB887; */
    height: 85px;
    display: flex;
    justify-content: center;
    /* padding: 0.2rem calc((100vw - 1000px) / 2); */
    width: 100%;
    z-index: 12;
}

.inner-nav{
  /* justify-content: space-between; */
  width: 70%;
  max-width: 700px;
  /* border-color: rgba(0, 0, 0, 0.141); */
  /* border-color: rgba(2, 1, 0, 0.551);
  border-width: 0 0 2px 0;
  border-style: solid;
  border-radius: 5%; */

  @media screen and (max-width: 768px) {
    border: none;
  }
}

.buttonTile{
  /* margin-right: 1%; */
  margin-left: 1%;
  padding: 2% 5%;
  width: 25vw;
  min-width: 300px;
  height: 75px;
}

.button-tile-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 10px;
  text-align: center;
}

.button-tile {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.button-tile:hover {
  filter: brightness(90%);
}

.resume-container{
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-template-rows: auto auto auto auto;
  padding: 1%;
  background-color: rgba(250,240,230,0.6);
  height: 80vh;
}

.nav-container-right{
  display: inline-flex;
  float: right;
  height: 85px;
  width: auto;

  @media screen and (max-width: 768px) {
    position: absolute;
    top: 0;
    right: 0;
    padding-right: 20px;
  }
}

.nav-container-left{
  display: inline-flex;
}

.background {
  /* background-image: url('../public/assets/lake-oakland-tree.JPG'); */
  /* background-size: 100vh 100vw; */
  /* background-size: 100vh auto; */
  
  align-items: center;
  height: auto;
  width: calc(100vw - 17px);
}

.nav a.active {
  font-size: 1.2rem;
  /* text-decoration: underline; */
}

.nav-container-right a.active {
  font-size: 1rem;
}

.container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.container-text {
  text-align: left;
  justify-content: center;
  /* margin-left: 5%; */
  margin: 2em 0 0 5%;
  width: 50%;
  /* padding-right: 1% */
}

@media (max-width:771px) {
  .container-text {
    /* text-align: left; */
    justify-content: center;
    width: 100%;
    margin: 0 0 0 5%;
  }

  .buttonTile {
    white-space: pre-line;
    padding: 4% 0%;
    margin: 2% 0%;
    width: 75vw;
  }
  
  .justify {
    
    text-align: left;
    justify-content: center;
    width: 100%;
  }

  .bookImgContainerMobile{
    display: flex;
    flex-grow: 1;
    justify-content: center;
  }

  .bookImgContainer{
    display: none;
  }
}

.container-picture {
  display: flex;
  flex-grow: 1;
  justify-content: center;
}

.container-golf-map {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  background-image: url("/public/assets/michigan-map.png");
  background-repeat: no-repeat;
  width: fit-content;
  min-height: 400px;
  
}

.icn-link{
  display: flex;
  align-items: center;
  margin-right: 5px;
  /* justify-content: end; */
  padding: 0;
}

.icn-img {
  height: 40px;
  text-align: center;
  width: auto;
  padding: 0;
}

.logo-img {
  height: 85px;
  width: auto;
  text-align: center;
  padding-right: 10px;
  display: inline-flex;

  @media screen and (max-width: 768px) {
    position: absolute;
    top: 0;
    left: 0;
    padding-left: 20px;
  }
}