.contact-form-container {
  width: 300px;
  margin: 0 auto;
}

.contact-form-container h2 {
  color: #6d4c41; /* Brown */
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
  color: #8d6e63; /* Brownish grey */
}

input[type='text'],
input[type='email'],
textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #d7ccc8; /* Light brown */
  border-radius: 5px;
}

button {
  background-color: #B2C6B2  ; /* Light yellow */
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  color: #6d4c41;
}


button:hover {
  background-color: #C0D2C0; /* Lighter shade of yellow */
}