.carousel {
    position: relative;
    padding-top: 2%;
    display: flex;
    justify-content: left;
    align-items: center;
    /* width: 50%; */
    width: fit-content;
    height: 400px;
}

.carousel-image {
    width: 100%;
    height: 100%;
    border-radius: 0.5rem;
    box-shadow: 0px 0px 7px #666;
    transition: transform 0.3s ease-in-out;
    @media screen and (max-width: 768px) {
      width: 300px;

    }
}

.carousel-image:hover {
    transform: scale(1.05);
  }

.carousel-image-hidden {
    display: none;
}

.carousel__btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.25);
    color: white;
    font-size: 1.5rem;
    border: none;
    padding: 1rem;
    cursor: pointer;
    z-index: 1;
    transition: background-color 0.3s;
  }
  .carousel__btn:hover {
    background-color: rgba(0, 0, 0, 0.5);
  }
  .carousel__btn--prev {
    left: 0;
  }
  .carousel__btn--next {
    right: 0;
  }

  .carousel__cnt {
    position: absolute;
    align-items: center;
    right: 0;
    transform: translateX(-50%);
    /* top: 100%;
    transform: translateY(-100%); */
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    font-size: 0.5rem;
    border: none;
    padding: 0.5rem;
    cursor: pointer;
    z-index: 1;
    bottom: 0;
  }




